import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { MapUpdateProcess, FindOutMoreButton, Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="text-xl leading-relaxed">
      <div className="bg-gray-100 sm:p-16 p-4">
  In tourism we see digitization without digitalization. We see that
  digitalization only focuses on the sales process but leaves aside the
  important customer experience. Poor implementation leads to complicated and
  unnecessary processes that can leave the customers annoyed and frustrated.
  Find out how to use technology to enhance your customers experience, and to
  optimize your own processes.
      </div>
      <br />
      <Image src="solutions/scan_qrcode_warm.jpg" className="sm:mx-16" mdxType="Image" />
      <p className="text-xs mt-2 text-center">
  Digitized information and a digitalized process.
      </p>
      <br />
      <h3>{`Digitization vs. digitalization`}</h3>
      <br />
      <p>{`Digitization and digitalization are not the same! Digitization is the first step
towards digitalization. But getting stuck with digitization can burden your
business with complicated and ineffective processes.`}</p>
      <br />
      <p>{`Only with the next step - the digital transformation of processes - the full potential
of digitalization can be exploited! But what is digitalization and the difference
to digitization?`}</p>
      <br />
      <br />
      <h4>{`Digitization`}</h4>
      <p>{`is the translation of the information of an analogue source material into a digital
format. In the case of a text or an image, it is the conversion of the information
on paper into bits and bytes that are stored on a digital medium. The digital
version of a paper map could be a PDF document. A scanner translates the colors
and shapes of the paper pixel by pixel into digital values of hue, saturation,
and brightness.`}</p>
      <br />
      <p>{`While paper is the medium to carry the colors and shapes of the physical map, a
computer and screen are required to display the digital information in a way that
the human eye can read.`}</p>
      <br />
      <p>{`A major benefit of digital data is that `}<b>{`duplicating`}</b>{` information becomes easy.
Imagine the process of making copies of a printed map. Paper, ink, print plate,
and a machine to apply it to the paper is required. There is a high material usage.
The setup, production, and post processing are time consuming and expensive.`}</p>
      <br />
      <p>{`A digital map, for example in the form of a PDF, can be copied thousands of times
very quickly. The tools to do that are so easy to use that almost everyone can do
it with a simple computer.`}</p>
      <br />
      <p>{`The `}<b>{`distribution`}</b>{` of digital information becomes a lot easier. Paper maps need to
be transported with vehicles and complicated logistic processes to the distribution
points. Each user must physically go to the distribution points to pick up the map.
A digital copy of a map can easily be sent via newsletter email, posted on social
media, or downloaded from a website by the users.`}</p>
      <br />
      <div className="flex sm:flex-no-wrap flex-wrap sm:mx-16">
  <Image src="news/map_box.jpg" className="sm:mr-4 sm:mb-0 mb-4" mdxType="Image" />
  <Image src="news/maps_skiloyper.jpg" className="sm:ml-4" mdxType="Image" />
      </div>
      <p className="text-xs mt-2 text-center">
  Distribution point for print maps outdoors.
      </p>
      <br />
      <p>{`That sounds like a big improvement already, but we need to be careful! Print
products are not created for display on screens, especially phones. The scale,
text size, colors and contrast are tailored to the large size of a paper map. It
can be complicated and frustrating to use the digital version!`}</p>
      <br />
      <Image src="news/golsfjellet-kart.jpg" className="sm:mx-16" mdxType="Image" />
      <p className="text-xs mt-2 text-center">
  Printed map that can't be digitalized and used on mobile screens.
      </p>
      <br />
      <p>{`And what happens when the map has to be updated? A paper map needs to be reprinted
and redistributed. But even the PDF stored on the user's computer or phone is not
accessible to the creator of the map. The information is dead, soon outdated, and
loses value both for the user and the destination.`}</p>
      <br />
      <p className="sm:mx-16 sm:p-16 p-4 bg-gray-100">
  “We translated information into a digital format, but didn’t utilize the
  technology to improve the process of creating and managing the information.”
      </p>
      <br />
      <p>{`Here we see the limits of digitization. We translated information into a digital
format, but didn’t utilize the technology to improve the process of creating and
managing the information. In order to realize the full potential, to increase
efficiency and value, we have to go one step further and find out what digitalization
is.`}</p>
      <br />
      <br />
      <h4>{`Digitalization`}</h4>
      <p>{`is the use of digital technology to enhance business processes in order to increase
the value of the product for the customer and to reduce the use of resources. For
the business this means higher turnover and lower costs.`}</p>
      <br />
      <p>{`In tourism we see isolated examples of digitalization such as booking platforms.
But they focus on the sales process and ignore most of the important customer
experience.`}</p>
      <br />
      <p>{`Let's go back to our example above and compare the processes of updating a paper
map, a digitized PDF map, and the digitalized process with a content management
system (CMS). For the CMS we use Trailguide as an example because we know it best,
but of course you can substitute it with other software.`}</p>
      <br />
      <p>{`A map has to be updated constantly. Information changes, new activities have to
be added, old ones removed. The map is the basic source of information for all
kinds of outdoor activities - which are the product of a tourism destination.`}</p>
      <br />
      <br />
      <div className="sm:hidden block italic text-sm">Scroll table to the right</div>
      <div className="overflow-x-auto">
  <MapUpdateProcess mdxType="MapUpdateProcess" />
  <p className="text-xs mt-2 text-center">
    Comparison of the different processes to update information on a map.
  </p>
      </div>
      <br />
      <br />
      <p>{`We can clearly see a big difference between the three processes. The process with
the digitized map has some advantages, but still lacks some of the most significant
improvements. Finally the digitalized process has great added value for the customer,
is faster and cheaper:`}</p>
      <br />
      <h4>{`Added value for the customer:`}</h4>
      <ul>
  <li>
    Users have all necessary information in their pocket. Large amounts of
    information can be saved and displayed in a compact, clear and easy to use
    way.
  </li>
  <li>
    Easy and quick accessibility of information, independent of time and
    location.
  </li>
  <li>Additional functions of an app like navigation.</li>
  <li>
    Easily find suitable activities for the individual demands with the search
    and filter for the interactive map and the activity browser.
  </li>
  <li>Users get reliable up-to-date information.</li>
  <li>
    Uniform medium to present and plan activities from home, on site, and during
    the activity.
  </li>
  <li>Users can leave feedback, and see feedback from others.</li>
      </ul>
      <br />
      <h4>{`Saved time and costs:`}</h4>
      <ul>
  <li>
    Maintaining a single source of content instead of changing content in many
    different places.
  </li>
  <li>Easy and quick to edit content.</li>
  <li>Changes are directly visible and published.</li>
  <li>Saves print costs. No print required, or reprint cycles reduced.</li>
  <li>
    Reduce maintenance costs by distributing or channeling traffic based on
    current conditions.
  </li>
      </ul>
      <br />
      <h4>{`Additional benefits:`}</h4>
      <ul>
  <li>Statistics and user feedback enable better decisions.</li>
  <li>
    Provide your guests with an app and meet the demands of the digital
    generation of customers.
  </li>
  <li>Increased reach by publishing your content on a popular platform.</li>
      </ul>
      <br />
      <Image src="solutions/laptop_phone_hafjell.jpg" className="sm:mx-16" mdxType="Image" />
      <p className="text-xs mt-2 text-center">
  Seamless integration on all platforms. Changing on source updates all
  communication channels.
      </p>
      <br />
      <br />
      <h3>{`So what does digitalization mean for tourism?`}</h3>
      <br />
      <p>{`First of all it means that there is a great potential for improvements! From our
experience, there are still many analogue processes or isolated digitization in
tourism. There are few examples of limited digitalization, like booking platforms.
But they represent a small part of the customer journey (the path a person takes
to become a customer of a product or service) only. It focuses on the sale process,
while the whole guest experience (consuming the product, e.g. the holiday, the
activities) is often left untouched.`}</p>
      <br />
      <Image src="solutions/scan_code_nesbyen.jpg" className="sm:mx-16" mdxType="Image" />
      <p className="text-xs mt-2 text-center">
  Scanning the QR-code of an infoboard to open the digital interactive map.
      </p>
      <br />
      <p>{`But the experience is the part that has value for the guest, that decides whether
the experience is good or bad. And when guests are happy, they stay longer, come
back, and recommend! Destinations, and all businesses in tourism can increase the
value for their guests, become more efficient and more competitive in the developing
digital world.`}</p>
      <br />
      <p>{`Digital technology offers great chances but should not be used as an end in itself.
To really benefit from digitalization you can ask yourself these questions:`}</p>
      <ul>
        <li>Does technology add value to the product?</li>
        <li>Does technology save resources like time and costs?</li>
        <li>How does technology help improve our processes in addition to the top level
performance indicators time and costs? This can include security, communication,
number of interfaces, decision making, employee satisfaction, market placement,
reach, or other important strategic or operational targets.</li>
        <li>Does technology make new processes possible?</li>
      </ul>
    </div>
    <div className="flex justify-center mt-12">
  <FindOutMoreButton to="/products" className="text-xl text-center" mdxType="FindOutMoreButton">
    Find out more about the Trailguide Software
  </FindOutMoreButton>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      